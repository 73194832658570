import React, {Suspense, lazy} from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Protected from "./Protected";

const SignIn = lazy(() => import('../container/SignIn'))
const AccessCode = lazy(() => import('../container/AccessCode'))
const Designlist = lazy(() => import('../container/DesignList'))
const Home = lazy(() => import('../container/Home'))
const NotFound = lazy(() => import('./NotFound'))
const ShareList = lazy(() => import('../container/ShareList'))

const Layout = () => {
    
    return(
        <>         
            <Header />   
            <Suspense fallback={<div className="align-self-center">Loading...</div>}>
            <Routes>
                <Route path="/" element={<SignIn />} />
                <Route path="/accesscode" element={<AccessCode />} />
                <Route path="/designlist" element={<Protected Component={Designlist} />} />
                <Route path="/home" element={<Protected Component={Home} />} />
                <Route path="/*" element={<NotFound />} />
                <Route path="/sharelist" element={<ShareList />} />
            </Routes>
            </Suspense>
            <Footer />            
        </>
    )
}

export default Layout