import React, {useState} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as SportexLogo } from '../assets/images/sportex-logo.svg';
import { ReactComponent as SportexLogoColor } from '../assets/images/sportex-logo-color.svg';
import { ReactComponent as CartIcon } from '../assets/images/cart-icon.svg';
import SearchBar from "./SearchBar";
import ShareSearchBar from "./ShareSearchBar";
import CartModal from "./Modal/Cart";
import { useSelector } from "react-redux";
import { isLogin } from "../features/accesscodeSlice";


const Header = () => {
    const {pathname} = useLocation()
    
    const [showCart, setShowCart] = useState(false)
      
    const handleCloseCart = () => setShowCart(false);
    const handleShowCart = () => setShowCart(true);
    const cartqty = useSelector(state => state.cart.cartTotalQuantity)
    const isLoggedIn = useSelector(isLogin)
    //const isLoggedIn = true

    const navigate = useNavigate()
    //console.log("isLoggedIn", isLoggedIn)

    if(pathname === "/" || pathname === "/accesscode") {
        return(
            <>
                <div className="header d-flex">
                    <div className="logo"><SportexLogo /></div>
                </div>
            </>
        )
    }
    if(pathname === "/sharelist") {
        return(
            <>
                <div className="header d-flex align-items-center">
                <div className="logocolor"><Link to="/home"><SportexLogoColor /></Link></div>
                <div className="minicart ms-auto order-3">
                    <button type="button" className="d-flex" onClick={handleShowCart}>
                        <div className="carticon"><CartIcon /> <span>{cartqty}</span></div> <div>DESIGN <span>CART</span></div> 
                    </button>
                </div>
                <ShareSearchBar className="px-5 topsearch" />
                <CartModal show={showCart} handleShow={handleShowCart} handleCloseCart={handleCloseCart} />
            </div>
            </>
        )
    }

    return(
        <>  
            {
                !isLoggedIn ? navigate('/') : 
            
            <div className="header d-flex align-items-center">
                <div className="logocolor"><Link to="/home"><SportexLogoColor /></Link></div>
                <div className="minicart ms-auto order-3">
                    <button type="button" className="d-flex" onClick={handleShowCart}>
                        <div className="carticon"><CartIcon /> <span>{cartqty}</span></div> <div>DESIGN <span>LIST</span></div> 
                    </button>
                </div>
                <SearchBar className={`px-5 topsearch ${pathname === '/home' ? 'd-none d-md-block' : ''}`} />
                <CartModal show={showCart} handleShow={handleShowCart} handleCloseCart={handleCloseCart} />
            </div>
             
            }
        </>
    )
}

export default Header